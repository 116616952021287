import * as React from "react"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';

import KathleenHeadshot from "../images/kathleen-barretto-headshot.jpg"

const IndexPage = () => {
  return (
    <main>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="author" content="Kathleen Barretto"></meta>
      <meta name="description" content="Kathleen Barretto's personal website."></meta>
      <title>Kathleen Barretto</title>
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>
      <div className="bg-white mx-auto px-6">
        <div className="text-left md:text-right font-sans text-black">
          <h1 className="text-3xl font-bold mt-12">Kathleen Barretto</h1>
          <ul className="text-base mt-8 leading-relaxed">
            <li>Founder at <a href="http://designstaq.com/" target="_blank" rel="noreferrer" className="font-sans text-jade">Designstaq</a></li>
            <li>Making things at <a href="http://tinysubmarine.com/" target="_blank" rel="noreferrer" className="font-sans text-jade">Tiny Submarine</a></li>
            <li>Just started writing on <a href="http://blog.kathleenbarretto.com/" target="_blank" rel="noreferrer" className="font-sans text-jade">my blog</a></li>
          </ul>
          <p className="text-base mt-4">Recently launched: <a href="http://bordr.io/" target="_blank" rel="noreferrer" className="font-sans text-jade">Bordr</a> and <a href="http://cocina.rlolabs.com/" target="_blank" rel='noreferrer' className='font-sans text-jade'>Cocina</a></p>
          <div className="flex flex-row justify-start mt-4 mb-12">
            <a href="mailto:kathleen@tinysubmarine.com" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={'envelope-square'} className="fill-current mr-4 w-20"/> 
            </a>
            <a href="https://calendly.com/designstaq/kathleen-30mins" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={'calendar-day'} className="fill-current mr-4"/>
            </a>
          </div>  
          
        </div>
      </div>
      <div className="mx-auto px-6">
        <div className="flex flex-row">
          <img src={KathleenHeadshot} alt="woman-holding-laptop-beside-table"/>
          </div>
      </div>
      <div className='h-96 bg-dark-jade flex flex-col justify-between -mt-72'>
      </div>
    </main>
  )
}

export default IndexPage
